<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <div style="">
        <v-row>
            <v-col>
                <v-container class="my-5 adminContainer">
                    <v-row>
                        <v-col cols="0" md="2">
                           <admin-stat-menu></admin-stat-menu>
                        </v-col>
                        <v-col cols="12" md="10">
                            <v-row>

                                <v-col cols="12" class="sub-title-3 mt-2">
                                    예약통계
                                </v-col>
                                <!--<v-col class="hidden-md-and-down">-->
                                <div class="overflow-x-auto">
                                    <table class="adminListTable">
                                        <colgroup>
                                            <col style="width:10%"/>
                                            <col style="width:18%"/>
                                            <col style="width:18%"/>
                                            <col style="width:18%"/>
                                            <col style="width:18%"/>
                                            <col style="width:18%"/>
                                        </colgroup>
                                        <tr>
                                            <th>
                                                <select v-model="resvYear" @change="changeYear($event)"
                                                        style="background-color: #fff;">
                                                    <option v-for="(n, i) in yearList" :value="n">{{n}}년</option>
                                                </select>
                                            </th>
                                            <th>캠핑장A</th>
                                            <th>캠핑장B</th>
                                            <th>캠핑장C</th>
                                            <th>객실</th>
                                            <th>합계</th>
                                        </tr>
                                        <tr
                                                v-for="(item,i) in statList"
                                                :key="i">
                                            <td>{{item.month}}월</td>
                                            <td>{{item.resvCount_A }}</td>
                                            <td>{{item.resvCount_B }}</td>
                                            <td>{{item.resvCount_C }}</td>
                                            <td>{{item.resvCount_R }}</td>
                                            <td>{{item.resvCount_tot }}</td>
                                        </tr>
                                        <tr>
                                            <td>합계</td>
                                            <td>{{statInfo.resvCount_A }}</td>
                                            <td>{{statInfo.resvCount_B }}</td>
                                            <td>{{statInfo.resvCount_C }}</td>
                                            <td>{{statInfo.resvCount_R }}</td>
                                            <td>{{statInfo.resvCount_tot }}</td>
                                        </tr>
                                    </table>
                                </div>
                            </v-row>
                            <v-row>
                                <v-col>
                                    ※입금완료된 내역만 포함됩니다.
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>

                </v-container>
            </v-col>
        </v-row>

    </div>
</template>

<script>
    import AdminStatMenu from "../../components/AdminStatMenu";
    export default {
        name: 'AdminStatMain',
        components: {AdminStatMenu},
        data: () => ({

            resvYear: "",
            yearList: [],
            statList: [],
            statInfo: {
                resvPrice_0: 0,
                resvPrice_1: 0,
                resvPrice_2: 0,
                resvPrice_3: 0,
                resvPrice_tot: 0,
            },

        }),
        created: function () {
            // 맨위로
            window.scrollTo(0, 0);
            //this.$isAdminCheck(this.$store.state.admin.isLogin);
            //this.$isAuthCheck(this.$getCookie("AUTH"));

            let now = new Date();	// 현재 날짜 및 시간
            let year = now.getFullYear();	// 연도
            this.resvYear = String(year);


            for (year; year > 2020; year--) {
                this.yearList.push(year);
            }

            this.getList();

        },
        methods: {
            getList() {

                let formdata = {};

                if (this.resvYear.length !== 4) {
                    alert("잘못된 요청입니다.");
                    return;
                }

                formdata['resvYear'] = this.resvYear;


                return this.$store.dispatch("admin/getCountMonth", formdata)
                    .then((resp) => {
                        setTimeout(() => {

                            this.statList = resp.message;
                            this.statInfo = resp.sumInfo;

                        }, 300)
                    })
                    .catch((err) => {
                    })
            },
            initPrice() {

            },
            menuClick(route, roomGubun) {
                if (roomGubun != null) {
                    location.href = route + '?roomGubun=' + roomGubun;
                } else {
                    location.href = route;
                }

            },
            changeYear(event) {
                this.resvYear = event.target.value;
                this.getList();

            }
        },
        mounted() {
            this.$nextTick(() => {

            })
        },
        computed: {
            //resvConfirmInfo() { return this.$store.state.reservation.resvConfirmInfo }
        },
    }
</script>

<style>

</style>

